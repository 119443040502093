import Container from '../container/container'
import { useTranslation } from 'react-i18next';
import aboutStyles from "./about.module.scss";

const valuesCard = [
    {
        title: "Respect",
        txt: "For our employees, partners and customers by understanding their needs and earning their trust.",
        // url: ""
    },
    {
        title: "Teamwork",
        txt: "Between our employees, partners and customers through Open communication and sharing of expertise and know-how."
    },
    {
        title: "Integrity",
        txt: "In our approach through focus, fulfillment of commitments and actively searching for the best outcome for our employees, partners and customers."
    },
    {
        title: "Honesty",
        txt: "In our relationships with employees, partners and suppliers to ensure the solutions we provide meet customer expectations."
    },
    {
        title: "Innovation",
        txt: "In harnessing and applying the latest technologies to provide customized, value added services for our customers."
    },
    {
        title: "Sustainability",
        txt: "By ensuring our services aid in the growth and success of our customers which will in turn lead to our own growth and success."
    }

]

const valuesCard_ar = [
    {
        title: "الإحترام",
        txt: "لموظفينا وشركائنا وعملائنا من خلال فهم احتياجاتهم وكسب ثقتهم.",
        // url: ""
    },
    {
        title: "العمل الجماعي",
        txt: "بين موظفينا وشركائنا وعملائنا من خلال التواصل المفتوح وتبادل الخبرات والمعرفة."
    },
    {
        title: "النزاهة",
        txt: "في نهجنا من خلال التركيز والوفاء بالالتزامات والبحث النشط عن أفضل النتائج لموظفينا وشركائنا وعملائنا."
    },
    {
        title: "الصدق",
        txt: "في علاقاتنا مع الموظفين والشركاء والموردين لضمان أن الحلول التي نقدمها تلبي توقعات العملاء."
    },
    {
        title: "الإبتكار",
        txt: "في تسخير وتطبيق أحدث التقنيات لتوفير خدمات مخصصة ذات قيمة مضافة لعملائنا."
    },
    {
        title: "الإستدامة",
        txt: "من خلال ضمان أن تساعد خدماتنا في نمو ونجاح عملائنا مما سيؤدي بدوره إلى نمونا ونجاحنا."
    }

]

export default function About() {
    const { t, i18n } = useTranslation();

    return (
        <Container>
            < h1 className={` title `}>{t('about')}</h1>

            <section className={`${aboutStyles.section_about} ${i18n.language === 'ar' && aboutStyles.section_about_ar}`}>

                <div className={aboutStyles.container}>
                    <div className={`${aboutStyles.about_brief} ${i18n.language === 'ar' && aboutStyles.about_brief_ar}`}>
                        {i18n.language === 'en' ?
                            <p className={aboutStyles.about_info}>
                                <span>
                                    <b>Arab Technical Co. Ltd. </b> was incorporated in 2004 with the purpose of providing support systems and services for Substations and Industrial sectors in Western Province of KSA.
                                </span>
                                <span>
                                    Since its creation, <b>ArabTech</b> has evolved into a multi-disciplined organization comprised of multiple divisions and affiliates. This evolution was inspired by the growing needs of customers all over the kingdom.
                                </span>
                                <span>
                                    As a part of this inspired evolution, <b>ArabTech</b> had established multiple divisions to provide engineering, project management and technical field services that includes the following services:
                                </span>
                            </p>
                            :
                            <p className={aboutStyles.about_info}>
                                <span>
                                    تأسست شركة التقنية العربية المحدودة في عام 2004 بهدف توفير أنظمة وخدمات الدعم لمحطات التوزيع والقطاعات الصناعية في المنطقة الغربية من المملكة العربية السعودية.
                                </span>
                                <span>
                                    منذ إنشائها، تطورت شركة التقنية العربية إلى منظمة متعددة التخصصات تتألف من أقسام وشركات تابعة متعددة. وقد استلهم هذا التطور من الاحتياجات المتزايدة للعملاء في جميع أنحاء المملكة.
                                </span>
                                <span>
                                    كجزء من هذا التطور الملهم، أنشأت شركة التقنية العربية أقسامًا متعددة لتقديم خدمات الهندسة وإدارة المشاريع والخدمات الميدانية الفنية التي تشمل الخدمات التالية:
                                </span>
                            </p>
                        }

                        <div className={aboutStyles.about_brief_list}>
                            <div className={aboutStyles.about_brief_list_item}>
                                <img src="../images/gallery/pic1.jpeg" alt="" />
                                <div>
                                    <img src="../images/about/Picture1.svg" alt="" className={aboutStyles.infoIcon} />
                                    <p>{t("about_brief_list_1")}</p>
                                </div>
                            </div>
                            <div className={aboutStyles.about_brief_list_item}>
                                <img src="../images/gallery/pic2.jpeg" alt="" />
                                <div>
                                    <img src="../images/about/Picture1.svg" alt="" className={aboutStyles.infoIcon} />
                                    <p>{t("about_brief_list_2")}</p>
                                </div>
                            </div>
                            <div className={aboutStyles.about_brief_list_item}>
                                <img src="../images/gallery/pic3.jpeg" alt="" />
                                <div>
                                    <img src="../images/about/Picture1.svg" alt="" className={aboutStyles.infoIcon} />
                                    <p>{t("about_brief_list_3")}</p>
                                </div>
                            </div>
                            <div className={aboutStyles.about_brief_list_item}>
                                <img src="../images/gallery/pic4.jpeg" alt="" />
                                <div>
                                    <img src="../images/about/Picture1.svg" alt="" className={aboutStyles.infoIcon} />
                                    <p>{t("about_brief_list_4")}</p>
                                </div>
                            </div>
                            <div className={aboutStyles.about_brief_list_item}>
                                <img src="../images/gallery/pic5.jpeg" alt="" />
                                <div>
                                    <img src="../images/about/Picture1.svg" alt="" className={aboutStyles.infoIcon} />
                                    <p>{t("about_brief_list_5")}</p>
                                </div>
                            </div>
                            <div className={aboutStyles.about_brief_list_item}>
                                <img src="../images/gallery/pic6.jpeg" alt="" />
                                <div>
                                    <img src="../images/about/Picture1.svg" alt="" className={aboutStyles.infoIcon} />
                                    <p>{t("about_brief_list_6")}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={aboutStyles.vision_mission}>
                        <div className={aboutStyles.vision}>
                            <h2>{t('vision')}</h2>
                            <p>{t('vision_msg')}</p>
                        </div>

                        <div className={aboutStyles.mission}>
                            <h2>{t('mission')}</h2>
                            <p>{t('mission_msg')}</p>
                        </div>
                    </div>

                    <div className={aboutStyles.values}>
                        <h2>{t('values')}</h2>
                        <p>{t('values_msg')}</p>

                        {i18n.language === 'ar' ?
                            <div className={aboutStyles.cardWrapper}>
                                {valuesCard_ar.map(val => (
                                    <div className={aboutStyles.card}>
                                        <img src="../images/about/Picture1.svg" alt="" className={aboutStyles.cardIcon} />
                                        <p>
                                            <b>{val.title}</b>
                                            {val.txt}
                                        </p>
                                    </div>
                                ))}
                            </div>
                            :
                            <div className={aboutStyles.cardWrapper}>
                                {valuesCard.map(val => (
                                    <div className={aboutStyles.card}>
                                        <img src="../images/about/Picture1.svg" alt="" className={aboutStyles.cardIcon} />
                                        <p>
                                            <b>{val.title}</b>
                                            {val.txt}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        }

                        {/* <Values /> */}
                    </div>

                </div>
            </section>
        </Container>
    )

}

// function Values() {
//     return <div className={aboutStyles.cardWrapper}>

//         {valuesCard.map(val => (
//             <div className={aboutStyles.card}>
//                 <img src="../images/about/Picture1.svg" alt="" className={aboutStyles.cardIcon} />
//                 <p>
//                     <b>{val.title}</b>
//                     {val.txt}
//                 </p>
//             </div>
//         ))}
//     </div>;
// }